// document.addEventListener("DOMContentLoaded", function () {
//   // Seleciona todos os botões com a classe 'btnComprar a'
//   const buttons = document.querySelectorAll(".btnComprar a");
//   // Seleciona a seção alvo
//   const targetSection = document.querySelector(".spotProduto");
//   // Seleciona o outro botão
//   const otherButton = document.querySelector(".fullBanner__link");

//   // Função para rolar até 60px acima da seção alvo
//   function scrollToTargetSection(event) {
//     event.preventDefault();
//     if (targetSection) {
//       // Calcula a posição da seção alvo e subtrai 60px
//       const targetPosition =
//         targetSection.getBoundingClientRect().top + window.pageYOffset - 60;
//       // Rola a página até a posição calculada
//       window.scrollTo({
//         top: targetPosition,
//         behavior: "smooth",
//       });
//     }
//   }

//   // Adiciona o ouvinte de evento de clique a cada botão
//   if (buttons) {
//     buttons.forEach((button) => {
//       button.addEventListener("click", scrollToTargetSection);
//     });
//   }
// });

if (window.innerWidth <= 991) {
  // Permite rolagem vertical e desativa a rolagem horizontal
  document.documentElement.style.overflowX = "hidden";
  document.documentElement.style.overflowY = "scroll";
  document.body.style.overflowX = "hidden";
  document.body.style.overflowY = "scroll";

  // Esconde a barra de rolagem no WebKit browsers
  let style = document.createElement("style");
  style.innerHTML = `
    html::-webkit-scrollbar, body::-webkit-scrollbar {
      display: none;
    }
  `;
  document.head.appendChild(style);
}
